// LanguageSwitcher.js
import React from "react";
import { useTranslation } from "react-i18next";
import { GrLanguage } from "react-icons/gr";

const LanguageSwitcher = () => {
	const { i18n } = useTranslation();

	const changeLanguage = (event) => {
		i18n.changeLanguage(event.target.value);
	};

	return (
		<div className="language-switcher">
			<GrLanguage className="language-icon" />
			<select onChange={changeLanguage}>
				<option value="es">Español</option>
				<option value="en">English</option>
			</select>
		</div>
	);
};

export default LanguageSwitcher;
