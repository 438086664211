import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LogoCPSVG from "../assets/logos/Logo CP-2021.svg";
import LogoCPSVGWhite from "../assets/logos/Logo CP-2021-white.svg";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { motion } from "framer-motion";
import SpeedDialTool from "./SpeedDialTool";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { IoHome } from "react-icons/io5";
import { IoIosContacts } from "react-icons/io";
import { GrGallery } from "react-icons/gr";
import { MdConstruction } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
	tabStyles: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
		position: "relative",
		"&:after": {
			content: '""',
			position: "absolute",
			bottom: 0,
			left: 0,
			width: "100%",
			borderBottom: "2px solid #0083bf",
			transform: "scaleX(0)",
			transition: "transform .3s ease",
		},
		"&:hover:after": {
			transform: "scaleX(1)",
			color: "#0083bf",
		},
	},
}));

const Root = styled("div")(({ theme }) => ({
	[theme.breakpoints.down("sm")]: {
		display: "none",
	},
	[theme.breakpoints.down("md")]: { display: "none" },

	[theme.breakpoints.up("sm")]: {},
}));

const HideMenu = styled("div")(({ theme }) => ({
	[theme.breakpoints.down("sm")]: {},
	[theme.breakpoints.down("md")]: {},

	[theme.breakpoints.up("md")]: {
		display: "none",
	},
}));

function ButtonAppBar(props) {
	//Variables y funciones para el menu temporal

	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const { curPage, setCurPage } = props;

	const handlePage = (event, newPage) => {
		setCurPage(newPage);
	};

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const list = (anchor) => (
		<Box
			sx={{
				backgroundColor: "#124582",
				color: "#fff",
				paddingY: "2rem",
				height: "100vh",
				width: "100vw",
			}}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}>
			<Typography
				sx={{
					fontSize: "2rem",
					fontFamily: "Yantramanav",
					fontWeight: 900,
					padding: "2rem 3rem",
					display: "flex",
					flexDirection: "column",
				}}>
				CONSTRUCCIONES{" "}
				<span style={{ fontSize: "1.5rem", opacity: 0.5 }}>PEÑARANDA S.A.</span>
			</Typography>
			<List
				sx={{
					cursor: "pointer",
					display: "flex",
					flexDirection: "column",
					gap: "1rem",
					paddingLeft: "0rem",
				}}>
				<ListItem
					sx={{ paddingLeft: "3rem", ":hover": { backgroundColor: "#448ee5" } }}
					button
					key={"Inicio"}
					component={Link}
					to="/"
					onClick={(event) => handlePage(event, "Inicio")}>
					<ListItemIcon
						sx={{
							color: "#fff",
							fontSize: "1.3rem",
							border: "solid 1px white",
							borderRadius: "50%",
							padding: "1rem",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							marginRight: "1rem",
						}}>
						<IoHome />
					</ListItemIcon>
					<ListItemText
						primary={
							<Typography sx={{ fontSize: "20px", fontWeight: 900 }}>
								{t("appMenuBar-inicio")}
							</Typography>
						}
					/>
				</ListItem>

				<ListItem
					sx={{ paddingLeft: "3rem", ":hover": { backgroundColor: "#448ee5" } }}
					button
					key={"Nuestra Empresa"}
					component={Link}
					to="/nuestraempresa"
					onClick={(event) => handlePage(event, "Empresa")}>
					<ListItemIcon
						sx={{
							color: "#fff",
							fontSize: "1.3rem",
							border: "solid 1px white",
							borderRadius: "50%",
							padding: "1rem",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							marginRight: "1rem",
						}}>
						<IoIosContacts />
					</ListItemIcon>
					<ListItemText
						primary={
							<Typography sx={{ fontSize: "20px", fontWeight: 900 }}>
								{t("appMenuBar-sobreNosotros")}
							</Typography>
						}
					/>
				</ListItem>

				<ListItem
					sx={{ paddingLeft: "3rem", ":hover": { backgroundColor: "#448ee5" } }}
					button
					key={"Proyectos"}
					component={Link}
					to="/proyectos"
					onClick={(event) => handlePage(event, "Proyectos")}>
					<ListItemIcon
						sx={{
							color: "#fff",
							fontSize: "1.3rem",
							border: "solid 1px white",
							borderRadius: "50%",
							padding: "1rem",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							marginRight: "1rem",
						}}>
						<GrGallery />
					</ListItemIcon>
					<ListItemText
						primary={
							<Typography sx={{ fontSize: "20px", fontWeight: 900 }}>
								{t("appMenuBar-proyectos")}
							</Typography>
						}
					/>
				</ListItem>

				<ListItem
					sx={{ paddingLeft: "3rem", ":hover": { backgroundColor: "#448ee5" } }}
					button
					key={"Servicios"}
					component={Link}
					to="/servicios"
					onClick={(event) => handlePage(event, "Servicios")}>
					<ListItemIcon
						sx={{
							color: "#fff",
							fontSize: "1.3rem",
							border: "solid 1px white",
							borderRadius: "50%",
							padding: "1rem",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							marginRight: "1rem",
						}}>
						<MdConstruction />
					</ListItemIcon>
					<ListItemText
						primary={
							<Typography sx={{ fontSize: "20px", fontWeight: 900 }}>
								{t("appMenuBar-servicios")}
							</Typography>
						}
					/>
				</ListItem>

				<ListItem
					sx={{
						paddingLeft: "3rem",
						":hover": { backgroundColor: "#448ee5" },
					}}
					button
					key={"Contacto"}
					component={Link}
					to="/contacto"
					onClick={(event) => handlePage(event, "Contacto")}>
					<ListItemIcon
						sx={{
							color: "#fff",
							fontSize: "1.3rem",
							border: "solid 1px white",
							borderRadius: "50%",
							padding: "1rem",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							marginRight: "1rem",
						}}>
						<MdEmail />
					</ListItemIcon>
					<ListItemText
						primary={
							<Typography sx={{ fontSize: "20px", fontWeight: 900 }}>
								{t("appMenuBar-contacto")}
							</Typography>
						}
					/>
				</ListItem>
			</List>
			<Divider />
			<img
				alt="foto"
				src={LogoCPSVGWhite}
				style={{
					width: "100%",
					height: "20%",
					padding: "1rem",
					objectFit: "contain",
					marignX: "auto",
				}}></img>
		</Box>
	);
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<Box>
			{["left", "right", "top", "bottom"].map((anchor) => (
				<React.Fragment key={anchor}>
					<Drawer
						anchor={anchor}
						open={state[anchor]}
						onClose={toggleDrawer(anchor, false)}>
						{list(anchor)}
					</Drawer>
				</React.Fragment>
			))}

			<AppBar
				position="fixed"
				sx={{
					backgroundColor: "rgba(255,255,255)",
					height: "5rem",
					boxShadow: "0px 3px 10px rgb(0 0 0 / 40%)",
					paddingX: "4rem",
				}}>
				<motion.div
					initial={{ opacity: 0, y: -20, x: 0 }}
					animate={{ opacity: 1, y: 0, x: 0 }}
					exit={{ opacity: 0, y: 20, x: -0 }}
					transition={{ delay: 0.5, type: "spring", stiffness: 60 }}
					whileHover={{ opacity: 0.8 }}>
					<Root>
						<Box
							position="fixed"
							sx={{
								width: "9rem",
								p: 1,
								height: "6rem",
								background: "rgba(255,255,255)",
								zIndex: 5000,
								marginTop: 0,
								marginLeft: "2rem",
								boxShadow:
									"0 15px 25px hsla(0, 0%, 0%, 0.15), 0 5px 10px hsla(0, 0%, 0%, 0.5)",
								borderBottomLeftRadius: 5,
								borderBottomRightRadius: 5,
							}}>
							<img
								alt="foto"
								src={LogoCPSVG}
								style={{
									height: "90%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									margin: "0 auto",
									objectFit: "contain",
								}}></img>
						</Box>
					</Root>
				</motion.div>

				<Toolbar
					sx={{
						alignItems: "center",
						justifyContent: "flex-end",
						height: "100%",
						padding: 0,
					}}>
					<HideMenu>
						<IconButton
							size="large"
							edge="start"
							color="inherit"
							aria-label="menu"
							sx={{ mr: 2, zIndex: 9999, position: "relative" }}
							onClick={toggleDrawer("left", true)}>
							<MenuIcon sx={{ color: "#124582", fontSize: "2rem" }} />
						</IconButton>
					</HideMenu>

					<motion.div
						initial={{ opacity: 0, y: -20, x: 0 }}
						animate={{ opacity: 1, y: 0, x: 0 }}
						exit={{ opacity: 0, y: 0, x: -0 }}
						transition={{ delay: 0.8 }}>
						<Root>
							<Tabs
								sx={{
									height: "3rem",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
								value={curPage}
								onChange={handlePage}
								aria-label="Pagina actual">
								<Tab
									sx={{
										padding: 0,
										fontSize: "0.7rem",
										letterSpacing: "0.1rem",
										fontWeight: 600,
										color: "black",
									}}
									className={classes.tabStyles}
									value="Inicio"
									label={t("appMenuBar-inicio")}
									component={Link}
									to="/"
								/>
								<Tab
									sx={{
										padding: 0,
										fontSize: "0.7rem",
										letterSpacing: "0.1rem",
										fontWeight: 600,
										color: "black",
									}}
									className={classes.tabStyles}
									value="Empresa"
									label={t("appMenuBar-sobreNosotros")}
									component={Link}
									to="/nuestraempresa"
								/>
								<Tab
									sx={{
										padding: 0,
										fontSize: "0.7rem",
										letterSpacing: "0.1rem",
										fontWeight: 600,
										color: "black",
									}}
									className={classes.tabStyles}
									value="Proyectos"
									label={t("appMenuBar-proyectos")}
									component={Link}
									to="/proyectos"
								/>
								<Tab
									sx={{
										padding: 0,
										fontSize: "0.7rem",
										letterSpacing: "0.1rem",
										fontWeight: 600,
										color: "black",
									}}
									className={classes.tabStyles}
									value="Servicios"
									label={t("appMenuBar-servicios")}
									component={Link}
									to="/servicios"
								/>
								<Tab
									sx={{
										padding: 0,
										fontSize: "0.7rem",
										letterSpacing: "0.1rem",
										fontWeight: 600,
										color: "black",
									}}
									className={classes.tabStyles}
									value="Contacto"
									label={t("appMenuBar-contacto")}
									component={Link}
									to="/contacto"
								/>
								<LanguageSwitcher />
							</Tabs>
						</Root>
					</motion.div>
				</Toolbar>
			</AppBar>
			<SpeedDialTool />
		</Box>
	);
}

export default ButtonAppBar;
