import React from "react";
import "./Loader.css";

const Loader = () => {
	return (
		<div
			style={{
				position: "absolute",
				width: "100%",
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}>
			<span className="loader"></span>
		</div>
	);
};

export default Loader;
