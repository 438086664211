import * as React from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useMediaQuery } from "@material-ui/core";

const actions = [
	{ icon: <LocationOnOutlinedIcon />, name: "Google Maps" },
	{ icon: <PhoneOutlinedIcon />, name: "Teléfono" },
	{ icon: <EmailOutlinedIcon />, name: "Correo" },
	{ icon: <FacebookOutlinedIcon />, name: "Facebook" },
	{ icon: <WhatsAppIcon />, name: "WhatsApp" },
];

export default function SpeedDialTool() {
	const handleClick = (e, action) => {
		if (action === "Google Maps") {
			window.open(
				"https://www.google.es/maps/place/Construcciones+Pe%C3%B1aranda+S.A./@10.1025406,-84.4674499,17z/data=!3m1!4b1!4m5!3m4!1s0x8fa045ff40b32715:0xc6ee3650ff8d44e!8m2!3d10.1025353!4d-84.4652612?hl=es"
			);
		}
		if (action === "Teléfono") {
			window.open("tel:24450254");
		}
		if (action === "Correo") {
			window.open("mailto:info@conpesa.com");
		}
		if (action === "Facebook") {
			window.open(
				"https://www.facebook.com/Construcciones-Pe%C3%B1aranda-SA-1490954697854648"
			);
		}
		if (action === "WhatsApp") {
			window.open("https://wa.me/50688726006");
		}
	};

	// Detect screen size
	const matches = useMediaQuery("(max-width:600px)");

	// Adjust font size based on screen size
	const showRadial = matches ? false : true;

	return (
		<Box sx={{ flexGrow: 1, position: "fixed", zIndex: 5000 }}>
			<SpeedDial
				ariaLabel="SpeedDial Conpesa"
				sx={{
					visibility: showRadial ? "visible" : "hidden",
					position: "fixed",
					bottom: "30vh",
					right: "1rem",
				}}
				icon={<SpeedDialIcon />}>
				{actions.map((action) => (
					<SpeedDialAction
						key={action.name}
						icon={action.icon}
						tooltipTitle={action.name}
						onClick={(e) => {
							handleClick(e, action.name);
						}}
					/>
				))}
			</SpeedDial>
		</Box>
	);
}
