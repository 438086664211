import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next"; // Import I18nextProvider from the 'react-i18next' package
import i18n from "./i18n";
import "./index.css";
import App from "./App";

ReactDOM.render(
	<React.StrictMode>
		<I18nextProvider i18n={i18n}>
			<App />
		</I18nextProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
