import ButtonAppBar from "./componentes/ButtonAppBar";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import React, { useState, lazy, Suspense } from "react";
import Loader from "./componentes/Loader";
const Inicio = lazy(() => import("./componentes/Inicio"));
const NuestraEmpresa = lazy(() => import("./componentes/NuestraEmpresa"));
const Proyectos = lazy(() => import("./componentes/Proyectos"));
const Servicios = lazy(() => import("./componentes/Servicios"));
const Contacto = lazy(() => import("./componentes/Contacto"));
const ScrollToTop = lazy(() => import("./componentes/ScrollToTop"));

function App() {
	const [curPage, setCurPage] = useState("Inicio");
	return (
		<div className="App">
			<Suspense fallback={<Loader />}>
				<BrowserRouter>
					<ScrollToTop />
					<ButtonAppBar curPage={curPage} setCurPage={setCurPage} />
					<Switch>
						<Route
							exact
							path="/"
							component={() => (
								<Inicio curPage={curPage} setCurPage={setCurPage} />
							)}
						/>
						<Route
							exact
							path="/nuestraempresa"
							component={() => (
								<NuestraEmpresa curPage={curPage} setCurPage={setCurPage} />
							)}
						/>
						<Route
							exact
							path="/proyectos"
							component={() => (
								<Proyectos curPage={curPage} setCurPage={setCurPage} />
							)}
						/>
						<Route
							exact
							path="/servicios"
							component={() => (
								<Servicios curPage={curPage} setCurPage={setCurPage} />
							)}
						/>
						<Route
							exact
							path="/contacto"
							component={() => (
								<Contacto curPage={curPage} setCurPage={setCurPage} />
							)}
						/>
					</Switch>
				</BrowserRouter>
				<ScrollToTop />
			</Suspense>
		</div>
	);
}

export default App;
