// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import esTranslation from "./locales/es.json";
import enTranslation from "./locales/en.json";

i18n.use(initReactI18next).init({
	resources: {
		es: {
			translation: esTranslation,
		},
		en: {
			translation: enTranslation,
		},
	},
	lng: "es", // set default language to "es"
	fallbackLng: "es", // fallback language
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
